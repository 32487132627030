<template>
    <div class="nav_title">
        <div class="name">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SubTitle"
    }
</script>

<style scoped>
    .nav_title {
        width: 100%;
        background-color: var(--subTitileBg);
        height:32px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        --border-radius: 5px;
    }

    .name {
        width: 100%;
        color: #fff9ff !important;
        font-weight: 800;
        font-size: 12px;
        --border-bottom: 4px solid #c51a1b;
        line-height: 32px;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .name img {
        width: 32px;
        height: 32px;
        margin-left: 15px;
    }
</style>